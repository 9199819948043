.input-file-container {
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.42);
    color: rgba(0, 0, 0, 0.42);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 120px;
    transition: all;
}
.input-file-container:hover {
    border: 1px solid #1f9992;
    color: #1f9992;
    font-weight: 600;
}

.input-file-container.error {
    border-color: red;
    color: red;
    font-weight: 600;
}
.input-file-container.error:hover {
    border-color: red;
    color: red;
}

.input-file-container.selected {
    border: 1px solid #1f9992;
    color: #1f9992;
    font-weight: 600;
}
.input-file-container.selected:hover {
    opacity: 0.7;
}

label + .MuiInput-formControl {
    margin: 0px !important;
}

.custom-reports-label {
    padding-top: 24px !important;
    padding-bottom: 6px !important;
}

.custom-reports-subtitle {
    margin: 0px !important;
}

.hovered {
    box-sizing: content-box;
    border-color: orange;
    border-style: dashed;
    border-width: 4px;
    opacity: 0.6;
}

input:invalid {
    border: "red solid 2px";
}
