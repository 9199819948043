.MuiListSubheader-root {
    opacity: 0.75;
    text-transform: uppercase;
    font-size: 95%;
}
.MuiListSubheader-root + .MuiMenuItem-root {
    padding-top: 0;
}
.MuiList-root > .MuiListSubheader-root {
    margin-top: .5em;
}
.MuiList-root > .MuiListSubheader-root:first-child {
    margin-top: 0;
}
