.MuiPopover-root {
    z-index: 9999 !important;
}

.select-dropdown-container .select-label {
    color: white;
    font-family: inherit;
}

.env-container .MuiFormLabel-root,
.env-container .MuiInputBase-root {
    color: white;
}

.env-container .MuiInput-underline:before {
    border-bottom: 1px solid white;
}

.env-container .MuiSelect-icon {
    color: white;
}
