.loading-screen {
    width: 100%;
    height: 100%;
    background-color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-screen__title {
    font-family: "Montserrat-Bold";
    font-size: 50px;
    color: #acacac;
}

.loading-screen__loader {
    width: 100%;
    height: 6px;

    position: absolute;
    top: 0;

    display: flex;
    justify-content: center;
}

.loading-screen__loader::before {
    content: "";
    display: block;

    width: 50%;
    height: 6px;
    background-color: var(--color-climate-green);

    animation: loading-screen__loader--animation 1s ease-out infinite;
}

@keyframes loading-screen__loader--animation {
    0% {
        width: 0%;
    }

    50% {
        width: 100%;
    }

    100% {
        width: 0%;
    }
}
