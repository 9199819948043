.coordinates-container {
    width: 100%;
    max-height: 300px;
    max-width: 100%;

    background: #f4f4f4;
    border: 1px solid #ddd;
    border-left: 3px solid #f36d33;
    color: #666;
    page-break-inside: avoid;
    font-family: monospace;
    font-size: 15px;
    line-height: 1.6;
    overflow: auto;
    padding: 0.5em 1em;
    display: block;
    word-wrap: break-word;
}

.coordinates-container::placeholder {
    color: rgba(102, 102, 102, 0.5);
}

.error-message {
    font-size: 12px;
    color: red;
    margin-bottom: 0px;
}

.error-message.bigger {
    text-align: center;
    font-size: 14px;
}
