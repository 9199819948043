.user-initial {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    color: white;
    background-color: var(--color-orange-climate);
    border-radius: 50%;
    margin-bottom: 8px;
}

.data-container {
    display: flex;
}
