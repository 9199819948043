.loading-bar-container {
    position: absolute;
    top: 0;
}

.add-user-modal {
    background-color: white;
    border-radius: 12px;
    padding: 24px;
    width: 500px;
    height: 400px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -250px;
    margin-top: -200px;
}

.MuiFormControl-root {
    width: 100%;
}

.error-message {
    font-size: 12px;
    color: red;
    margin-bottom: 0px;
}

.error-message.bigger {
    text-align: center;
    font-size: 14px;
}
